import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/Seo';

const IndexPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <div className="page-wrapper-inner">
        <Seo
          title="Welcome"
        />
        {/* Banner */}
        <section id="banner">
          <header
            data-aos="fade-in"
            data-aos-once="false"
            data-aos-duration="600"
            data-aos-offset="0"
            data-aos-delay="500"
          >
            <h2>
              Rosewood Family Advisors provides a diverse range of family office services customized for ultra high net worth individuals.
            </h2>
          </header>
        </section>

        <section
          id="mobile-intro"
          className="wrapper style1 section-with-header"
          data-aos="fade-in"
          data-aos-once="false"
          data-aos-duration="600"
          data-aos-offset="0"
          data-aos-delay="500"
          >
          <h2>
            Rosewood Family Advisors provides a diverse range of family office services customized for ultra high net worth individuals.
          </h2>
        </section>

        {/* Highlights */}
        <section id="highlights" className="wrapper wrapper-custom style1">
          <div className="container">

            {/* One */}
            <section
              id="one"
              className="wrapper wrapper-custom spotlight"
              data-aos="fade-in"
              data-aos-once="false"
              data-aos-duration="600"
              data-aos-offset="0"
            >
              <div className="content">
                <div>
                  <h2>Bill Pay and Accounting</h2>
                  <p>
                    Our bill pay and accounting services are customized to fit the complex needs of the ultra affluent individual.
                  </p>
                  <ul className="actions">
                    <li><Link to="/services/bill-pay-and-accounting/" className="button" rel="nofollow">Learn More</Link></li>
                  </ul>
                </div>
              </div>
              <div className="image anim-pop">
                <StaticImage
                  src="../assets/images/GettyImages-84831616.jpg"
                  alt=""
                  width={510}
                />
              </div>
            </section>

            {/* Two */}
            <section
              id="two"
              className="wrapper wrapper-custom spotlight alt"
              data-aos="fade-in"
              data-aos-once="false"
              data-aos-duration="600"
              data-aos-offset="0"
            >
              <div className="content">
                <div>
                  <h2>Income Tax Planning and Compliance</h2>
                  <p>
                    Our experience and proactive approach to tax planning will help minimize your tax burden and achieve your goals.
                  </p>
                  <ul className="actions">
                    <li><Link to="/services/income-tax-planning-and-compliance/" className="button" rel="nofollow">Learn More</Link></li>
                  </ul>
                </div>
              </div>
              <div className="image anim-pop">
                <StaticImage
                  src="../assets/images/GettyImages-169944220.jpg"
                  alt=""
                  width={510}
                />
              </div>
            </section>

            {/* Three */}
            <section
              id="three"
              className="wrapper wrapper-custom spotlight"
              data-aos="fade-in"
              data-aos-once="false"
              data-aos-duration="600"
              data-aos-offset="0"
            >
              <div className="content">
                <div>
                  <h2>Family Administrative Services</h2>
                  <p>
                    We provide reliable, comprehensive administrative services tailored to your family’s unique needs.
                  </p>
                  <ul className="actions">
                    <li><Link to="/services/family-administrative-services/" className="button" rel="nofollow">Learn More</Link></li>
                  </ul>
                </div>
              </div>
              <div className="image anim-pop">
                <StaticImage
                  src="../assets/images/GettyImages-500817285.jpg"
                  alt=""
                  width={510}
                />
              </div>
            </section>

            {/* Four */}
            <section
              id="four"
              className="wrapper wrapper-custom spotlight alt"
              data-aos="fade-in"
              data-aos-once="false"
              data-aos-duration="600"
              data-aos-offset="0"
            >
              <div className="content">
                <div>
                  <h2>Estate and Gift Planning</h2>
                  <p>
                    Our experienced professionals will assist you in implementing and developing a successful succession plan for your family’s wealth.
                  </p>
                  <ul className="actions">
                    <li><Link to="/services/estate-and-gift-planning/" className="button" rel="nofollow">Learn More</Link></li>
                  </ul>
                </div>
              </div>
              <div className="image anim-pop">
                <StaticImage
                  src="../assets/images/GettyImages-457980607.jpg"
                  alt=""
                  width={510}
                />
              </div>
            </section>


            {/* Five */}
            <section
              id="five"
              className="wrapper wrapper-custom spotlight"
              data-aos="fade-in"
              data-aos-once="false"
              data-aos-duration="600"
              data-aos-offset="0"
            >
              <div className="content">
                <div>
                  <h2>Philanthropic Consulting</h2>
                  <p>
                    Our team works with you to develop a comprehensive philanthropic plan so that your wealth will have a substantial and lasting impact.
                  </p>
                  <ul className="actions">
                    <li><Link to="/services/philanthropic-consulting/" className="button" rel="nofollow">Learn More</Link></li>
                  </ul>
                </div>
              </div>
              <div className="image anim-pop">
                <StaticImage
                  src="../assets/images/GettyImages-950232884.jpg"
                  alt=""
                  width={510}
                />
              </div>
            </section>

          </div>
        </section>

        {/* Bios */}
        <section id="bios" className="wrapper style1 section-with-header">
          <h2>Our professionals are here to help you define and meet your financial goals.</h2>
          <div className="container">
            <section className="bios row">

              <article
                className="col-4 col-12-mobile"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="image">
                  <Link to="/people/#bios1">
                    <StaticImage
                      src="../assets/images/bios-tina_rosado.jpg"
                      alt=""
                      width={224}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h3><Link to="/people/#bios1">Tina Rosado</Link></h3>
                  <p><em>Managing Partner</em></p>
                </div>
              </article>

              <article
                className="col-4 col-12-mobile"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="image">
                  <Link to="/people/#bios2">
                    <StaticImage
                      src="../assets/images/bios-harvey_armstrong.jpg"
                      alt=""
                      width={224}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h3><Link to="/people/#bios2">Harvey L. Armstrong</Link></h3>
                  <p><em>Founding Partner</em></p>
                </div>
              </article>

              <article
                className="col-4 col-12-mobile"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="image">
                  <Link to="/people/#bios3">
                    <StaticImage
                      src="../assets/images/bios-david_kenyon.jpg"
                      alt=""
                      width={224}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h3><Link to="/people/#bios3">David R. Kenyon</Link></h3>
                  <p><em>Partner</em></p>
                </div>
              </article>

              <article
                className="col-4 col-12-mobile"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="image">
                  <Link to="/people/#bios4">
                    <StaticImage
                      src="../assets/images/bios-kerry_bratton.jpg"
                      alt=""
                      width={224}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h3><Link to="/people/#bios4">Kerry Bratton</Link></h3>
                  <p><em>Partner</em></p>
                </div>
              </article>

              <article
                className="col-4 col-12-mobile"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="image">
                  <Link to="/people/#bios5">
                    <StaticImage
                      src="../assets/images/bios-cayce_craven.jpg"
                      alt=""
                      width={224}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h3><Link to="/people/#bios5">Cayce Craven</Link></h3>
                  <p><em>Partner</em></p>
                </div>
              </article>
            </section>
          </div>
          <footer>
            <Link to="/people/">View more</Link>
          </footer>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
